import { useTheme } from '@mui/material';
import Chart from 'react-apexcharts';

export interface ChartUsage {
	series?: LineGraphProps['series'];
	xAxis?: LineGraphProps['xAxis'];
	tooltip?: LineGraphProps['tooltip'];
}
export interface LineGraphProps {
	series?: ApexAxisChartSeries | ApexNonAxisChartSeries;
	xAxis?: ApexXAxis;
	tooltip?: ApexTooltip;
	height?: string | number;
	width?: string | number;
	stacked?: boolean;
	title?: string;
}

export function LineGraph({
	series,
	xAxis,
	height,
	width,
	title,
	tooltip,
	stacked = false,
}: LineGraphProps) {
	const theme = useTheme();
	return (
		<>
			<Chart
				options={{
					theme: {
						mode: theme.palette.mode,
					},
					chart: {
						id: 'area-line',
						stacked,
						background: theme.palette.background.default,
					},
					noData: {
						text: 'No data',
					},
					xaxis: xAxis,
					tooltip,
					title: { text: title },
				}}
				series={series}
				type="area"
				height={height}
				width={width}
			/>
		</>
	);
}
