import { CssBaseline } from '@mui/material';
import { lazy } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import { Nav } from './components/Nav';
import { RoleProvider } from './components/RoleProvider';
import { SnackbarOutlet } from './components/SnackbarOutlet';
import { store } from './store/store';
import { AppThemeProvider } from './utils/Theme';

const Home = lazy(() => import('./pages/Home'));
const Login = lazy(() => import('./pages/Login'));
const Forgot = lazy(() => import('./pages/ForgotPassword'));
const ResetPassword = lazy(() => import('./pages/Reset'));
const Licenses = lazy(() => import('./components/license/AllLicenses'));
const License = lazy(() => import('./pages/License'));
const Users = lazy(() => import('./pages/Users'));
const Customer = lazy(() => import('./components/customer/Customer'));
const Organization = lazy(() => import('./components/organization/OrganizationHome'));

function App() {
	return (
		<Provider store={store}>
			<BrowserRouter>
				<RoleProvider>
					<AppThemeProvider>
						<CssBaseline />
						<>
							<Routes>
								<Route path="/" element={<Nav />}>
									<Route path="/" element={<Home />} />
									<Route path="/license" element={<Licenses />} />
									<Route path="/login" element={<Login />} />
									<Route path="/users" element={<Users />} />
									<Route path="/organization" element={<Organization />} />
									<Route path="/customer/:id" element={<Customer />} />
									<Route path="/license/:id" element={<License />} />
									<Route path="/forgot" element={<Forgot />} />
									<Route path="/reset/:code" element={<ResetPassword />} />
								</Route>
							</Routes>
							<SnackbarOutlet />
						</>
					</AppThemeProvider>
				</RoleProvider>
			</BrowserRouter>
		</Provider>
	);
}

export default App;
